
import Ax from 'axios';
import Moment from 'moment';
import { getCookie } from '../utils/cookie';


export const fetchUserData = (queryObj) => {
    return async (dispatch) => {
        if (!Ax.defaults.headers.common['Authorization']) {
            return false
        }
        dispatch({
            type: "AF_AGENCY_LOADING_DATA",
            payload: {
                loading: 1
            }
        })
        try {

            if (!queryObj.fromDate && !queryObj.toDate) {
                queryObj = {
                    ...queryObj,
                    ...{
                        fromDate: Moment.utc().startOf("day").format(),
                        toDate: Moment.utc().format()
                    }
                }
            }
            // console.info("AUTH",)

            const apiUrl = `/api/user/initial-data`;
            const response = await Ax.post(
                apiUrl,
                queryObj,
                //  {
                //     headers: {
                //         'Authorization': getCookie('token')
                //     }
                // }
                );
            const payload = response.data;


            dispatch(payload);
            //   Router.push("/");


        } catch (e) {
            // dispatch({
            //   type: 'APPS_FLYER_LOADING_FAILED'
            // })
            if (e.name === 'Error') {
                dispatch({
                    type: 'TRACKRC_CONN_LOST',
                    notification: {
                        title: "TrackRC DB Connection Error",
                        message: "Please report and try again later",
                        status: 'critical',
                        dismissMs: 60000
                    }
                })
            }
            console.info(e)
            console.info(e.name)
        }


    }
}


export default {
    fetchUserData
}