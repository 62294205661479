import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

// reducer imports
import homepageReducer from "./homepageReducer";
import user from "./userReducer";
import notification from "./notificationReducer";
import reports from "./dashboardxReducer";
// import appsflyer_adn from "./appsflyerAdnReducer";
// import appsflyer_combined from "./appsflyerCombinedReducer";
import mainSidebar from "./mainSidebarReducer";
import rightSidebar from "./rightSidebarReducer";

// import networks from "./networksReducer";
// import advertisers from "./advertisersReducer";
// import cronLogs from "./cronLogsReducer";
// import offers from "./offersReducer";
// import extOffers from "./extOffersReducer";
// import conversions from "./conversionsReducer";

// import publishers from "./publishersReducer";
// import placements from "./placementsReducer";
// import statistics from "./statisticsReducer";
// import skadnRedirects from "./skadnRedirectsReducer";
import appbar from "./appbarReducer";
import { createResponsiveStateReducer } from "redux-responsive";
// import dashboard from './dashboardReducer';

export const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    notification,
    mainSidebar,
    rightSidebar,
    appbar,
    homepage: homepageReducer,
    reports,
    // dashboard,
    // appsflyer,
    // appsflyer_adn,
    // appsflyer_combined,
    // networks,
    // advertisers,
    // cronLogs,
    // skadnRedirects,
    // offers,
    // conversions,
    // extOffers,
    // publishers,
    // placements,
    // statistics,
    browser: createResponsiveStateReducer(null, {
      extraFields: () => ({
        width: window.innerWidth,
      }),
    }),
  });
