const fulltheme = {
  global: {
    colors: {
      brand: "#3c8dbc" /*main color code*/,
      placeholder: "",
    },
    font: {
      family: "Roboto",
      size: "15px",
      height: "20px",
    },
  },
  textInput: {
    extend: {
      fontWeight: "100",
      fontSize: "9px",
      height: "3vh",
      backgroundColor: "white",
    },
  },
  checkBox: {
    extend: {
      fontSize: "10px",
      width: "100%",
      whiteSpace: "pre",
    },
  },
  collapsible: {
    minSpeed: "100",
    baseline: "1950",
  },

  dateInput: {
    icon: { size: "12px" },
  },
  select: {
    control: {
      extend: `width:100%; border-radius: 5px; background-color: white`,
    },
    icons: {
      margin: 0,
    },
  },
  button: {
    // border: { radius: "1px" },
  },
}

  const theme = {
    global: {
      colors: {
        brand: "#3c8dbc" /*main color code*/,
        placeholder: "",
      },
      font: {
        family: "Roboto",
        size: "15px",
        height: "20px",
      },
    },

  }

export default theme;
