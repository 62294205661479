import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  Heading,
  Paragraph,
  Button,
  TextInput,
  Text,
  Box,
  DateInput,
  Select,
  Grommet,
} from "grommet";
import Loader from "../../components/Loader";
import ObjectHash from "object-hash";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core/styles";
import SummaryByDateChart from "../../components/Charts/SummaryByDate/SummaryByDateChart";
import ActivePieChart from "../../components/Charts/ActivePieChart/ActivePieChart";
import Filters from "../../components/Filters2/Filters";
import SimplePieChart from "../../components/Charts/SimplePieChart/SimplePieChart";
// import DatePresets from "./DatePresets";
import ChartContainer from "../../components/Charts/ChartContainer";
import DashboardXActions, {
  fetchUserData,
} from "../../actions/DashboardXActions";
import AppBarActions from "../../actions/AppBarActions";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    // backgroundColor: "red",
    // "& .MuiTextField-root": {
    //   margin: theme.spacing(1),
    //   width: "25ch",
    // },
    [theme.breakpoints.down("sm")]: {
      height: "fit-content",
      //   overflowY: "auto",
    },
  },
  filters: {
    display: "flex",
    // backgroundColor: "w",
    // borderBottom: "1px solid gray",
    alignItems: "center",
    boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.15)",
    // height: "10%",
    // padding: "0 10px",
    width: "100%",
  },
  piesRow: {
    // backgroundColor: "green",
    // padding: "15px 0",
    height: "42%",
    // width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(1),
      //   height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      //   justifyContent: "space-evenly",
      alignItems: "center",
      height: "fit-content",
    },
  },
  pieChart: {
    // padding: "100px 0",
    width: "31%",
    // height: "100%",
    // margin: "10px 0",
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
  bottomChart: {
    //  backgroundColor: "yellow",
    height: "50%",
    width: "100%",
    // padding: "15px 0",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      // padding: theme.spacing(1),
      //   height: "100%",
      //   opacity: 0,
    },
  },
}));

function DashboardXHome(props) {
  const classes = useStyles();

  const { browser, dashboard, dashboardx, loading, reports, fetchUserData } =
    props;
  const { noData } = dashboardx;

  const isMobile = browser.width < 800;
  const maxWidth = browser.width;

  const [topHeight, setTopHeight] = React.useState(null);
  const [bottomHeight, setBottomHeight] = React.useState();
  const [dataFilters, setDataFilters] = useState({});
  const [bottomRefRenderd, setBottomRenderd] = React.useState(false);
  const [topRefRenderd, setTopRenderd] = React.useState(false);
  const [queryCriteria, setQueryCriteria] = React.useState(
    dashboardx.queryCriteria
  );
  const prevQueryCriteria = usePrevious(dashboardx.queryCriteria);

  const topRef = React.useRef();
  const bottomRef = React.useRef();

  // console.info('compare',{ queryCriteria, prevQueryCriteria })
  React.useEffect(() => {
    async function getData() {
      await fetchUserData({
        ...queryCriteria,
      });
    }
    if (
      !noData &&
      ((reports && !reports.length && !loading) ||
        (prevQueryCriteria &&
          ObjectHash(prevQueryCriteria) !== ObjectHash(queryCriteria)))
    )
      getData();
    if (bottomRefRenderd && topRefRenderd && !bottomHeight && !topHeight) {
      setTopHeight(topRef.current.clientHeight);
      setBottomHeight(bottomRef.current.clientHeight);
    }
  }, [bottomRef.current, topRef.current, queryCriteria]);

  const reportsSource = reports || [];

  const filteredData = reportsSource.filter((r) => {
    const filters = Object.keys(dataFilters);
    if (!filters.length) {
      return r;
    }
    return (
      filters
        .map((filter) => {
          const values = dataFilters[filter].map(({ value }) =>
            value.toUpperCase()
          );
          if (!values.length) return true;
          const resp = values.indexOf(r[filter].toUpperCase()) > -1;
          return resp;
        })
        .filter((o) => o).length === filters.length
    );
  });

  const reportsData = filteredData;

  // const groupByOpts = getGroupByOpts(groupBy);

  const onDataFilter = ({ option, value: nextValue, ...rest }) => {
    const { type } = option || {};
    if (nextValue.length === 1 && typeof nextValue[0] === "undefined") {
      nextValue = [option];
    }
    setDataFilters({
      ...dataFilters,
      [type]: nextValue,
    });
  };

  return (
    <Loader loading={loading || reports.length < 1} text="Loading Reports...">
      <div className={classes.root}>
        <div className={classes.filters}>
          <Filters
            fields={[
              {
                name: "dateRange",
                type: "dateRange",
                width: "350px",
              },
              {
                name: "dateRange",
                type: "dateSelect",
                defaultRange: queryCriteria.datePreset,
                // value: toDate,
                // placeholder: `to date`
              },
              {
                name: "app_bundle",
                type: "selectFilterData",
                external: true,
                options: dashboardx.options.app_bundle,
                value: dataFilters["app_bundle"],
                onChange: onDataFilter,
              },
              {
                name: "app_bundle",
                type: "selectFilterData",
                external: true,
                options: dashboardx.options.app_bundle,
                value: dataFilters["app_bundle"],
                onChange: onDataFilter,
              },
            ]}
            queryCriteria={queryCriteria}
            handleSubmit={setQueryCriteria}
          />
        </div>
        <div
          className={classes.piesRow}
          ref={(r) => {
            topRef.current = r;
            setTopRenderd(true);
          }}
          style={{
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <div className={classes.pieChart}>
            <ChartContainer name={"Profit Bundle"}>
              <ActivePieChart
                //   loading={loading}
                maxWidth={maxWidth}
                profitBundle={dashboard.profitBundle}
                containerHeight={topHeight}
              />
            </ChartContainer>
          </div>
          <div className={classes.pieChart}>
            <ChartContainer name={"Performance"}>
              <SimplePieChart
                data={props.performance}
                containerHeight={topHeight}
                maxWidth={maxWidth}
              />
            </ChartContainer>
          </div>
          <div className={classes.pieChart}>
            <ChartContainer name={"Profit Publisher"}>
              <ActivePieChart
                maxWidth={maxWidth}
                profitBundle={dashboard.profitPub}
                containerHeight={topHeight}
              />
            </ChartContainer>
          </div>
        </div>

        <div
          className={classes.bottomChart}
          ref={(r) => {
            bottomRef.current = r;
            setBottomRenderd(true);
          }}
        >
          <div style={{ width: isMobile ? "100%" : "97%", padding: "8px" }}>
            <ChartContainer name={"Summary by date"}>
              <SummaryByDateChart
                //   loading={loading}
                data={dashboard.summary}
                maxWidth={maxWidth}
                containerHeight={bottomHeight}
              />
            </ChartContainer>
          </div>
        </div>
      </div>
    </Loader>
  );
}

const mapStateToProps = (state, props) => {
  const dashboardx = state.reports;
  const dashboard = state.reports.dashboard;
  const reports = state.reports.reports;
  const loading = state.reports.loading;
  const browser = state.browser;

  const performance = [];

  if (dashboard.summary) {
    const reducedSummary = dashboard.summary.reduce(
      (a, b) => ({
        cost: a.cost + b.cost,
        revenue: a.revenue + b.revenue,
        profit: a.profit + b.profit,
      }),
      { cost: 0, revenue: 0, profit: 0 }
    );

    Object.keys(reducedSummary).forEach((name) =>
      performance.push({ name, value: reducedSummary[name] })
    );
  }

  console.log(performance);

  return { dashboard, browser, performance, loading, reports, dashboardx };
};

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, DashboardXActions, AppBarActions),
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardXHome);
