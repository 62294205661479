import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // height: "100%",
    borderRadius: "5px",
    boxShadow: "0px 4px 5px 1px rgba(0,0,0,0.10)",
    // backgroundColor: "rgba(0,0,0,0.01)",
  },
  chartContainer: {
    border: "1px solid rgba(0,0,0,0.10)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
  chart: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // height: "100%",
    width: "100%",
    borderRadius: "5px",

    // margin: "7% 0%",
    // backgroundColor: "white",
    // boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.25)",
  },
  chartHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "7%",
    // backgroundColor: "#3c8dbc",
    padding: "5px 1px",
    color: "#c4c4c4",
    borderRadius: "5px 5px 0  0",
    // boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.35)",
    // zIndex: 100,
  },
  name: {
    fontWeight: "200",
    fontSize: "14px",
    textTransform: "capitalize",
    marginLeft: "10px",
    fontFamily: "Roboto",
  },
  info: {
    // marginRight: "1%",
  },
}));

function ChartContainer(props) {
  const classes = useStyles();

  const { children, name } = props;

  return (
    <div className={classes.root}>
      {/* <div className={classes.chartHeader}>
        <div className={classes.name}>{name}</div>
      </div> */}
      <div className={classes.chartContainer}>
        <div className={classes.chart}>{children}</div>
      </div>
    </div>
  );
}

export default ChartContainer;
