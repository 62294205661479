import {
  Home,
  Analytics,
  PersonalComputer as PublishersIcon,
  System as ManagementIcon,
  Code as ApisIcon,
  BarChart as ReportsIcon,
  Ad,
  CaretUpFill,
  CaretDownFill,
  Logout as LogoutIcon,
} from "grommet-icons";

export const navigationLinks = [
  // {
  //   src: "",
  //   name: "dashboard",
  //   icon: <Home color="white" size="18px" />,
  // },
  {
    src: "",
    icon: <ReportsIcon color="white" size="18px" />,
    name: "dashboard",
  },
  {
    src: "reports",
    icon: <ReportsIcon color="white" size="18px" />,
    name: "reports",
  },
  // {
  //   src: "advertisers",
  //   icon: <Ad color="white" size="18px" />,
  //   child: [
  //     {
  //       name: "Advertisers",
  //       src: "advertisers",
  //       icon: "",
  //     },
  //     {
  //       name: "Offers",
  //       src: "offers",
  //       icon: "",
  //     },
  //   ],
  // },
  // {
  //   src: "publishers",
  //   icon: <PublishersIcon color="white" size="18px" />,
  //   child: [
  //     {
  //       name: "Publishers",
  //       src: "publishers",
  //       icon: "",
  //     },
  //     {
  //       name: "Placements",
  //       src: "placements",
  //       icon: "",
  //     },
  //   ],
  // },

  // {
  //   src: "appsflyer",
  //   icon: <Analytics color="white" size="18px" />,
  //   child: [
  //     {
  //       name: "Reports",
  //       src: "af-combined/reports",
  //       icon: "",
  //     },
  //     {
  //       name: "Config",
  //       src: "af-combined/config",
  //       icon: "",
  //     },
  //     {
  //       name: "Events",
  //       src: "af-combined/events",
  //       icon: "",
  //     },
  //     {
  //       name: "Charts",
  //       src: "af-combined/charts",
  //       icon: "",
  //     },
  //   ],
  // },
  // {
  //   src: "api",
  //   icon: <ApisIcon color="white" size="18px" />,
  //   child: [
  //     {
  //       name: "Networks",
  //       src: "integrations/networks",
  //       name: "networks",
  //       // icon: <Nodes color="white" />,
  //     },
  //     {
  //       name: "External Offers",
  //       src: "external-offers",
  //       // icon: <Nodes color="white" />,
  //     },
  //   ],
  // },

  // {
  //   src: "management",
  //   icon: <ManagementIcon color="white" />,
  //   child: [
  //     {
  //       name: "Crons",
  //       src: "crons",
  //       icon: "",
  //     },
  //     {
  //       name: "SKADN Redirects",
  //       src: "skadnRedirects",
  //       // icon: <Redo color="white" />,
  //     },
  //   ],
  // },
];
