import React from "react";
import { Box, TextInput, Select, CheckBox, DateInput, Button } from "grommet";
import SelectSearchInput from "../xComponents/SearchInput";
import Moment from "moment";
import _ from "lodash";
import { CSVLink } from "react-csv";
import { Download } from "grommet-icons";
import styled from "styled-components";

const MButton = styled(Button)`
  border-radius: 0;
`;

function ReportDownloadButtonMaterial({ reportData }) {
  if (!reportData) return null;
  const now = Moment().format();
  const rows = reportData.length;

  const formattedDates = reportData.map((o) => {
    return Object.assign({}, o, {
      date: Moment.utc(o.date).add("5", "hours").format("YYYY-MM-DD"),
    });
  });
  const data = _.orderBy(formattedDates, ["revenue"], ["desc"]);

  // const hash = ObjectHash(reportData);
  let buttonProps = {
    primary: true,
    // icon: <Download />,
    label: "Download Report",
  };

  if (!reportData.length) {
    return <Button {...buttonProps} label={"No Data"} disabled={true} />;
  }
  return (
    <CSVLink
      data={data}
      filename={`${now}-${rows}.csv`}
      style={{
        textDecoration: "none",
      }}
    >
      <Button
        variant="contained"
        style={{
          fontSize: "8px",
          height: "34px",
          textTransform: "capitalize",
          fontFamily: "Roboto",
          fontWeight: 400,
          // border: "1px solid rgba(0,0,0,0.4)",
          whiteSpace: "pre",
          color: "gray",
          borderRadius: "5px",
          boxShadow: "none",
          border: "1px solid lightgray",
          padding: "0 10px",
        }}
      >
        Download Reports
      </Button>
    </CSVLink>
  );
}

const StyledButtonComponent = styled(Button)`
  background-color: ${(props) => props.background || "#3c8dbc"};
  color: ${(props) => props.color || "white"};
  width: ${(props) => (props.width ? props.width : "fit-content")};
  white-space: pre;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "10px")};
  padding: ${(props) => (props.padding ? props.padding : "4px 4px")};
  // margin: ${(props) => (props.margin ? props.margin : "0")};
  text-align: center;
  border-radius: 2.5px;
`;

const DownloadButton = styled(CSVLink)`
  // display: flex;
  text-decoration: none;
  // font-weight: bold;
  white-space: pre;
  letter-spacing: 4px;
  justify-content: center;
  height: 100%;
  width: 100%;
  & > button {
    background-color: transparent;
    color: #3c8dbc;
    font-weight: 500;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    font-size: 10px;
    padding: 2px 4px;
    line-height: 20px;
    & > div {
      // letter-spacing: 1px;
      & > svg {
        height: 100%;
        // width: 19px;
      }
    }
  }
`;

const DateRangeInput = styled(DateInput)`
  min-width: 280px !important;
`;

function ReportDownloadButton({ reportData }) {
  if (!reportData) return null;
  const now = Moment().format();
  const rows = reportData.length;

  const formattedDates = reportData.map((o) => {
    return Object.assign({}, o, {
      date: Moment.utc(o.date).add("5", "hours").format("YYYY-MM-DD"),
    });
  });
  const data = _.orderBy(formattedDates, ["revenue"], ["desc"]);

  // const hash = ObjectHash(reportData);
  let buttonProps = {
    primary: true,
    // icon: <Download />,
    label: "Download Report",
  };

  if (!reportData.length) {
    return <Button {...buttonProps} label={"No Data"} disabled={true} />;
  }
  return (
    <DownloadButton data={data} filename={`${now}-${rows}.csv`}>
      <Button {...buttonProps} />
    </DownloadButton>
  );
}
const DownloadButton2 = styled(CSVLink)`
  // background-color: ${(props) => props.background || "#3c8dbc"};
  color: ${(props) => props.color || "white"};
  // width: ${(props) => (props.width ? props.width : "fit-content")};
  white-space: pre;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "10px")};
  padding: 1px;
  border-radius: 0px;
  margin: ${(props) => (props.margin ? props.margin : "0")};
  text-align: center;
`;

function ReportDownloadButton2({ reportData }) {
  if (!reportData) return null;
  const now = Moment().format();
  const rows = reportData.length;

  const formattedDates = reportData.map((o) => {
    return Object.assign({}, o, {
      date: Moment.utc(o.date).add("5", "hours").format("YYYY-MM-DD"),
    });
  });
  const data = _.orderBy(formattedDates, ["revenue"], ["desc"]);

  // A new component based on Button, but with some override styles

  let buttonProps = {
    primary: true,
    icon: <Download size="12px" />,
    label: "Download Report",
  };

  if (!reportData.length) {
    return (
      <StyledButtonComponent
        {...buttonProps}
        label={"No Data"}
        disabled={true}
      />
    );
  }
  return (
    <DownloadButton2 data={data} filename={`${now}-${rows}.csv`}>
      <StyledButtonComponent {...buttonProps} />
    </DownloadButton2>
  );
}

function StyledButton(props) {
  // A new component based on Button, but with some override styles
  console.info({ props }, "STYLE_BUTTON");
  return (
    <StyledButtonComponent {...props}>{props.label}</StyledButtonComponent>
  );
}

function MobileButton(props) {
  return (
    <Box fill="horizontal">
      <MButton style={{ height: 40 }} onClick={props.onClick} primary>
        <Box direction="row" pad="6px" gap="6px">
          <Box style={{ fontSize: 14, fontWeight: 700 }}>{props.label}</Box>
          <Box style={{ fontSize: 12, fontStyle: "italic" }}>{props.desc}</Box>
        </Box>
      </MButton>
    </Box>
  );
}

function SelectFilterData(props) {
  const {
    name: fieldName,
    value,
    options,
    // groupByFilters,
    onChange,
    onClose,
  } = props;

  const orderedOptions = React.useCallback(
    (unorderedOptions) => {
      const values = value || [];
      const valuesLabels = values.map((o) => o.label);

      return [
        ...values,
        ...unorderedOptions.filter((o) => valuesLabels.indexOf(o.label) === -1),
      ];
    },
    [value]
  );

  const [opts, setOpts] = React.useState([...orderedOptions(options)]);

  React.useEffect(() => {
    setOpts(orderedOptions(options));
  }, [options, orderedOptions]);

  const placeholder =
    fieldName === "app_bundle" ? "App Bundle" : _.capitalize(fieldName);

  // console.info(opts, options, fieldName)
  return (
    <Select
      key={fieldName}
      id={`select-${fieldName}`}
      name={`${fieldName}`}
      placeholder={placeholder}
      value={value}
      closeOnChange={false}
      onSearch={(text) => {
        console.info({ text });
        if (!text) return true;
        const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&");
        const exp = new RegExp(escapedText, "i");
        // [...values, ...options.filter(o => labels.indexOf(o.label) === -1 && )]

        setOpts(options.filter((o) => exp.test(o.label)));
      }}
      searchPlaceholder={`Search ${placeholder}`}
      emptySearchMessage={`No ${placeholder} found`}
      onClose={() => {
        if (onClose) onClose();
        // setOpts(=(options))
      }}
      multiple
      messages={{
        multiple: `${value && value.length} ${placeholder}s`,
      }}
      // size="xsmall"
      valueKey="value"
      labelKey="label"
      options={opts}
      onChange={(...e) => {
        onChange(...e);
        // setOpts((options))
      }}
    />
  );
}

function DateSelect(props) {
  const { handleChange } = props;
  const options = [
    "Today",
    "Yesterday",
    "Last 7 Days",
    "Month To Date",
    "Last Month",
    "This Year",
  ];

  const dateMap = {
    Today: {
      fromDate: Moment().startOf("day").format(),
      toDate: Moment().endOf("day").format(),
    },
    Yesterday: {
      fromDate: Moment().subtract("1", "day").startOf("day").format(),
      toDate: Moment().subtract("1", "day").endOf("day").format(),
    },
    "Last 7 Days": {
      fromDate: Moment().subtract("7", "day").startOf("day").format(),
      toDate: Moment().endOf("day").format(),
    },
    "Month To Date": {
      fromDate: Moment().startOf("month").format(),
      toDate: Moment().endOf("day").format(),
    },
    "Last Month": {
      fromDate: Moment().subtract("1", "month").startOf("month").format(),
      toDate: Moment().subtract("1", "month").endOf("month").format(),
    },
    "This Year": {
      fromDate: Moment().startOf("year").format(),
      toDate: Moment().endOf("day").format(),
    },
  };

  const defaultRange = props.defaultRange || "Last 7 Days";
  const defaultOption = options.find(
    (o) => o.toLowerCase() === defaultRange.toLowerCase()
  );

  const [value, setValue] = React.useState(defaultOption);

  return (
    <Select
      options={options}
      value={value}
      onChange={({ option }) => {
        if (dateMap[option]) {
          console.info({ option }, dateMap[option]);
          // console.info()
          handleChange(null, {
            ...dateMap[option],
            datePreset: option,
          });
        }
        setValue(option);
      }}
    />
  );
}

export default function Field(props) {
  const { criteria, handleFiltersChange, field } = props;

  switch (field.type) {
    case "search":
      return (
        <TextInput
          size="small"
          icon={field.icon}
          name={field.name}
          type={field.type}
          placeholder={field.placeholder || field.name}
          onChange={(e) => handleFiltersChange(e)}
        />
      );
    case "checkbox":
      return (
        <CheckBox
          size="small"
          icon={field.icon}
          name={field.name}
          label={field.name}
          type={field.type}
          checked={criteria[field.name]}
          placeholder={field.placeholder || field.name}
          onChange={(e) => handleFiltersChange(e)}
        />
      );
    case "select":
      if (field.key) {
      }

      return (
        <Select
          size="small"
          // labelKey={field.key ? "label" : false}
          name={field.name}
          type={field.type}
          multiple={field.multiple}
          options={field.options}
          value={
            field.options.filter((p) => p === criteria[field.name])[0] ||
            "Both (combined)"
          }
          // valueKey={field.key ? { key: "value" } : false}
          placeholder={field.placeholder || field.name}
          onChange={(event) => handleFiltersChange(event)}
          // disabledKey={false}
        />
      );
    case "simpleSelect":
      if (field.key) {
      }

      return (
        <Select
          clear
          name={field.name}
          type={field.type}
          multiple={field.multiple}
          options={field.options}
          value={
            field.options.filter((p) => p === criteria[field.name])[0] || ""
          }
          placeholder={field.placeholder || field.name}
          onChange={(event) => handleFiltersChange(event)}
          // disabledKey={false}
        />
      );
    case "selectSearch":
      return (
        <SelectSearchInput
          value={criteria[field.name]}
          name={field.name}
          type={field.type}
          placeholder={field.placeholder || field.name}
          onChange={(e) => handleFiltersChange(e)}
          options={field.options}
        />
      );
    case "date":
      return (
        <DateInput
          key={`${criteria[field.name]}`}
          size="small"
          name={field.name}
          value={criteria[field.name]}
          format="dd/mm/yyyy"
          clear
          // type={field.type}
          inputProps={{
            value: criteria[field.name],
            placeholder: field.placeholder ? field.placeholder : "",
          }}
          onChange={({ value }) => {
            if (value) {
              return handleFiltersChange(null, { [field.name]: value });
            }
          }}
          style={{
            backgroundColor: "white",
            width: "100%",
            borderRadius: 5,
            fontWeight: 100,
            fontSize: "9px",
            height: "3vh",
            padding: "12px",
          }}
        />
      );

    case "dateRange":
      const dateFormat = new Intl.DateTimeFormat(undefined, {
        month: "long",
        day: "numeric",
      });

      return (
        <DateRangeInput
          // key={`${filters.startDate}${filters.endDate}`}
          value={[criteria.fromDate, criteria.toDate]}
          // inline={true}
          // format="yyyy/mm/dd-yyyy/mm/dd"
          buttonProps={{
            style: {
              minWidth: "150px",
              width: "100%",
            },
            label: `${dateFormat.format(
              new Date(criteria.fromDate)
            )} - ${dateFormat.format(new Date(criteria.toDate))}`,
          }}
          onChange={({ value }) => {
            if (value) {
              return handleFiltersChange(null, {
                fromDate: value[0],
                toDate: value[1],
              });
            }
          }}
        />
      );
    case "dateSelect":
      return (
        <DateSelect
          // disabled={disableSubmit}
          defaultRange={field.defaultRange}
          handleChange={handleFiltersChange}
        />
      );
    case "afGroupByFilter": {
      return (
        <Select
          id="select"
          name="Group By"
          placeholder={"Group By"}
          value={field.value}
          options={field.options}
          onChange={field.onChange}
          // valueLabel={
          //   field.value === "id" ? "Group By" : `By ${field.value}`
          // }
        />
      );
    }
    case "selectFilterData": {
      return <SelectFilterData {...field} />;
    }
    case "reportDownloadButton": {
      return <ReportDownloadButtonMaterial {...field} />;
    }
    // BUTTONS
    case "button": {
      switch (field.name) {
        case "reportDownload":
          return <ReportDownloadButton2 {...field} />;
        default:
          return <StyledButton {...field} />;
      }
    }

    default:
      return <Box>{field.name}</Box>;
  }
}
