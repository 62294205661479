import { createBrowserHistory } from "history";

import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import reduxPromise from "redux-promise-middleware";
// import root reducer
import { rootReducer, createRootReducer } from "./reducers/rootReducer";
// enhancers
import { routerMiddleware } from "connected-react-router";
import {
  responsiveStoreEnhancer,
  calculateResponsiveState,
} from "redux-responsive";

export const history = createBrowserHistory();
window.addEventListener("resize", () =>
  store.dispatch(calculateResponsiveState(window))
);

const allEnhancers = compose(
  responsiveStoreEnhancer,
  applyMiddleware(routerMiddleware(history), reduxPromise /*()*/, thunk),
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (f) => f
);

export const store = createStore(
  createRootReducer(history), // root reducer with router state
  allEnhancers
);

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    allEnhancers
  );

  return store;
}
