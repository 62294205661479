import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation } from "react-router-dom";

import SidebarsActions from "../actions/SidebarsActions";
import { Box, Button, Heading, Text, G } from "grommet";
import { Menu, Close, Notification as NotificationIcon } from "grommet-icons";

import VersionText from "../components/VersionInfo";
import _ from "lodash";

//
// AppBar
//
const AppBar = (props) => {
  // redux state
  const {
    isLoggedIn,
    mainSidebarVisible,
    toggleMainSidebar,
    toggleRightSidebar,
    browser,
  } = props;

  const isDesktop = browser.greaterThan.medium;
  const icons = [
    <Menu size="18px" />,
    <Close size="18px" />,
    <NotificationIcon size="18px" />,
  ];

  const notificationButtonStyle = {
    width: "100%",
    height: "100%",
    margin: "auto",
    textAlign: "center",
    // borderLeft: "1px solid #edea4a",
  };

  const location = useLocation();
  //   console.log("APPBAR", location.pathname.substring(1));
  //   console.log("APPBAR", injectedContent.title);

  // parse page header from the path
  let pageHeader = _.capitalize(location.pathname.substring(1).split("/")[1]);

  pageHeader = pageHeader
    ? pageHeader
    : _.capitalize(location.pathname.substring(1));

  // console.info({ pageHeader })
  if (pageHeader.indexOf("-") > -1) {
    pageHeader = pageHeader
      .split("-")
      .map((o) => _.capitalize(o))
      .join(" ");
  }

  return (
    <Box
      pad="none"
      tag="header"
      align="center"
      direction="row"
      justify="between"
      background="brand"
      elevation="medium"
      style={{ zIndex: "1", height: "50px" }}
      {...props}
    >
      <Box
        gap="small"
        margin="none"
        align="center"
        direction="row"
        justify="start"
        // background="#2a6283"
        style={{ height: "50px" }}
        width={`${browser.greaterThan.medium && "200px"}`}
      >
        {
          // User is logged in ?
          isLoggedIn && (
            <Button
              hoverIndicator
              onClick={toggleMainSidebar}
              icon={mainSidebarVisible ? icons[1] : icons[0]}
            />
          )
        }
        {
          // View is on desktop ? show App Name & version
          isDesktop && (
            <Heading level="3" margin="none" style={{ fontWeight: "400" }}>
              Dashboard
              <span style={{ color: "#edea4a", fontWeight: "700" }}>X</span>
              <VersionText />
            </Heading>
          )
        }
      </Box>

      <Box
        fill
        flex
        pad="small"
        // height="5vh"
        align="center"
        justify={"center"}
        direction="row"
      >
        {/*
         * PAGE TITLE
         */}
        <Text size="20px">{pageHeader}</Text>
      </Box>

      <Box
        direction="row"
        align="center"
        // height="5vh"
        justify="center"
        // border="left"
        // background="#2a6283"
        width={!isDesktop ? "" : "3%"}
      >
        {isLoggedIn && (
          <Button
            hoverIndicator
            icon={icons[2]}
            onClick={toggleRightSidebar}
            style={notificationButtonStyle}
          />
        )}
      </Box>
    </Box>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(SidebarsActions, dispatch);
}
function mapStateToProps(state) {
  const mainSidebarVisible = state.mainSidebar.visible;
  const { isLoggedIn } = state.user;
  const injectedContent = state.appbar;
  const browser = state.browser;
  return { isLoggedIn, mainSidebarVisible, injectedContent, browser };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);
