import Numeral from "numeral";
import React, { PureComponent } from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import { Box, Text } from "grommet";
import CountUp from "react-countup";

const styles = (...values) => {
  console.log(values);

  return {
    profitLabel: {
      fontSize: "15px",
    },
  };
};

const COLORS = {
  profit: "#2c699a", //'#4F6272',
  cost: "#EF3054",
  revenue: "#43AA8B",
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function SimplePieChart(props) {
  const demoUrl =
    "https://codesandbox.io/s/pie-chart-with-customized-label-dlhhj";

  const { data, maxWidth, containerHeight } = props;

  return (
    <Box
      flex
      fill
      align="center"
      style={{
        position: "relative",
        right: "0",
      }}
    >
      <div
        style={{
          borderRadius: "5px",
          // minWidth: "20%",
          padding: "10px 6px",
          position: "absolute",
          right: 10,
          bottom: 5,
          display: "flex",
          flexDirection: "column",
          fontWeight: 300,
          textTransform: "capitalize",
          alignItems: "center",
          // width: "30%",
          // fontSize: "17px",
          // zIndex: 100,
          // backgroundColor: "#5e5e5e",
          // fontFamily: "Roboto",
        }}
      >
        {["Profit"].map((name) => {
          const entry = data.find((d) => d.name === name.toLowerCase());

          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                fontWeight: "400",
                color: "rgba(0,0,0,0.30)",
              }}
            >
              <div>
                <Text
                  // color={COLORS[entry.name]}
                  style={{ marginRight: "10px" }}
                  size={maxWidth < 1500 ? "15px" : "15px"}
                >
                  {name}
                </Text>
              </div>
              <div>
                <CountUp
                  start={entry.value * 0.9}
                  end={entry.value}
                  duration={1}
                  separator=","
                  // decimals={4}
                  // decimal=","
                  prefix="$"
                  // suffix=" left"
                  // onEnd={() => console.log('Ended! 👏')}
                  // onStart={() => console.log('Started! 💨')}
                >
                  {({ countUpRef, start }) => (
                    <Text
                      ref={countUpRef}
                      // color={COLORS[entry.name]}
                      // weight="bold"
                      size={maxWidth < 1500 ? "15px" : "15px"}
                    >
                      {`${Numeral(entry.value).format("$0,0")}`}
                    </Text>
                  )}
                </CountUp>
              </div>
            </div>
          );
        })}
      </div>
      <PieChart
        width={340}
        height={maxWidth > 1600 ? containerHeight / 1.1 : 260}
      >
        {console.log({
          filters: containerHeight,
          maxWidth: maxWidth,
          maxWidthl: maxWidth > 1600,
        })}
        <Pie
          data={props.data.filter((d) => d.name !== "revenue")}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={maxWidth < 1500 ? 70 : 90}
          fill="#8884d8"
          dataKey="value"
        >
          {["Profit"].map((name, index) => {
            // console.info({ name });
            const entry = data.find((d) => d.name === name.toLowerCase());
            return <Cell key={`cell-${index}`} fill={COLORS[entry.name]} />;
          })}
        </Pie>
      </PieChart>
    </Box>
  );
}
