import React, { Component, useState, useEffect, useRef } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Moment from "moment";
import DashboardXActions, {
  fetchUserData,
} from "../../actions/DashboardXActions";
import AppBarActions from "../../actions/AppBarActions";
import MomentTZ from "moment-timezone";
import FiltersV2 from "../../components/Filters2/Filters";
import _ from "lodash";
// grommet
import {
  Box,
  Grommet,
  Button,
  DropButton,
  Text,
  Heading,
  Layer,
  Select,
  Tip,
} from "grommet";
import { Notification } from "grommet-controls";
import Loader from "../../components/Loader";
import DataTable from "../../components/DataTable";
import { CSVLink } from "react-csv";
import ObjectHash from "object-hash";
import {
  getRowProps,
  getTableColumns,
  getGroupByOpts,
} from "./DashboardXFuncs";

function ReportDownloadButton({ reportData }) {
  if (!reportData) return null;
  const now = Moment().format();
  const rows = reportData.length;

  const formattedDates = reportData.map((o) => {
    return Object.assign({}, o, {
      date: Moment.utc(o.date).add("5", "hours").format("YYYY-MM-DD"),
    });
  });
  const data = _.orderBy(formattedDates, ["revenue"], ["desc"]);

  // const hash = ObjectHash(reportData);
  let buttonProps = {
    primary: true,
    // icon: <Download />,
    label: "Download Report",
  };

  if (!reportData.length) {
    return <Button {...buttonProps} label={"No Data"} disabled={true} />;
  }
  return (
    <CSVLink
      data={data}
      filename={`${now}-${rows}.csv`}
      style={{
        textDecoration: "none",
      }}
    >
      <Button
        variant="contained"
        style={{
          fontSize: "8px",
          height: "34px",
          textTransform: "capitalize",
          fontFamily: "Roboto",
          fontWeight: 400,
          // border: "1px solid rgba(0,0,0,0.4)",
          whiteSpace: "pre",
          color: "gray",
          borderRadius: "5px",
          boxShadow: "none",
          border: "1px solid lightgray",
          padding: "0 10px",
        }}
      >
        Download Reports
      </Button>
    </CSVLink>
  );
}
const DEFAULT_SORT = { property: "revenue", direction: "desc" };

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function DashboardXReports(props) {
  const { fetchUserData, dashboardx, userPublishers } = props;
  const { loading, options, reports } = dashboardx;

  // const [filtersVisible, toggleFilterVisibility] = useState(true);
  const [dataFilters, setDataFilters] = useState({});
  const [groupBy, setGroupBy] = useState("id");
  const [firstLoad, setFirstLoad] = useState(true);
  // const [fromDate, setFromDate ] = useState(Moment.utc().subtract(24,'hours'));

  const [sortSettings, setSortSettings] = useState(DEFAULT_SORT);
  const [page, setCurrentPage] = useState(props.page || 1);
  const [pageSize, setCurrentPageSize] = useState(props.pageSize || 50);

  const [queryCriteria, setQueryCriteria] = useState(dashboardx.queryCriteria);
  const prevQueryCriteria = usePrevious(queryCriteria);

  let sort = sortSettings;
  if (
    ["id", "price", "name", "created", "start_time"].indexOf(sort.property) ===
    -1
  ) {
    sort = DEFAULT_SORT;
  }

  const dataTableKey = groupBy + (reports || []).length;
  const groupByProp = ["id", "none"].indexOf(groupBy) === -1 ? { groupBy } : {};

  // console.info('compare',{ queryCriteria, prevQueryCriteria })
  useEffect(() => {
    async function getData() {
      await fetchUserData({
        ...queryCriteria,
        // page,
        // pageSize,
        // sort,
      });
    }
    // const debounced = debounce(getData, 1000);
    // // debounced();
    // if (!firstLoad)
    if (
      (reports && !reports.length && !loading) ||
      (prevQueryCriteria &&
        ObjectHash(prevQueryCriteria) !== ObjectHash(queryCriteria))
    )
      getData();
  }, [loading, reports, queryCriteria, fetchUserData, prevQueryCriteria]);

  const reportsSource = reports || [];

  const filteredData = reportsSource.filter((r) => {
    const filters = Object.keys(dataFilters);
    // console.info(filters, 'filters')
    if (!filters.length) {
      return r;
    }
    // console.info(filters.map(filter => dataFilters[filter].map(({value}) => value)))
    return (
      filters
        .map((filter) => {
          const values = dataFilters[filter].map(({ value }) =>
            value.toUpperCase()
          );
          if (!values.length) return true;
          // console.info({filter, values}, r[filter])
          const resp = values.indexOf(r[filter].toUpperCase()) > -1;
          // if (resp)
          //   console.info(resp, r)
          return resp;
        })
        .filter((o) => o).length === filters.length
    );
  });

  const reportsData = filteredData;

  // console.info({filteredData, dataFilters, reports})
  const sumClicks = reportsData.length
    ? reportsData.map((o) => o.clicks).reduce((a, b) => a + b)
    : 0;

  const groupByOpts = getGroupByOpts(groupBy);

  const onDataFilter = ({ option, value: nextValue, ...rest }) => {
    const { type } = option || {};
    if (nextValue.length === 1 && typeof nextValue[0] === "undefined") {
      nextValue = [option];
    }
    // console.info({dataFilters, type, nextValue})
    setDataFilters({
      ...dataFilters,
      [type]: nextValue,
    });
  };

  return (
    <Box
      flex
      fill
      width="100%"
      margin="none"
      overflowY="auto"
      alignSelf="center"
    >
      {/* FILTERS  */}
      <div
        style={{
          display: "flex",
          // backgroundColor: "w",
          // borderBottom: "1px solid gray",
          alignItems: "center",
          boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.5)",
          width: "100%",
        }}
      >
        <FiltersV2
          fields={[
            {
              name: "dateRange",
              type: "dateRange",
            },
            {
              name: "dateRange",
              type: "dateSelect",
              defaultRange: queryCriteria.datePreset,
            },
            {
              name: "groupByFilter",
              type: "afGroupByFilter",
              options: groupByOpts,
              value: groupBy,
              external: true,
              onChange: ({ option }) => {
                const groupBy = option !== "none" ? option : "id";
                setGroupBy(groupBy);
              },
            },
            ...["publisher", "country", "campaign"].map((name) => {
              return {
                name,
                options: options[name],
                type: "selectFilterData",
                value: dataFilters[name],
                onChange: onDataFilter,
                external: true,
              };
            }),
            {
              name: "app_bundle",
              type: "selectFilterData",
              external: true,
              options: options.app_bundle,
              value: dataFilters["app_bundle"],
              onChange: onDataFilter,
            },
            {
              name: "reportDownload",
              type: "reportDownloadButton",
              external: true,
              reportData: reportsData,
            },
          ]}
          queryCriteria={queryCriteria}
          handleSubmit={setQueryCriteria}
        />
      </div>
      {userPublishers.length ? null : (
        <Box>
          <Notification
            size="medium"
            message="No Publishers"
            state="Please contact account manager."
            status="warning"
          />
        </Box>
      )}

      {/* DATA TABLE  */}
      <Loader loading={loading} text="Loading AppsFlyer data...">
        <Box style={{ flex: 13, overflowX: "auto" }}>
          <DataTable
            pin
            // fill
            key={groupBy + reportsData.length}
            rowProps={getRowProps(reportsData)}
            background={{
              header: "dark-3",
              // body: ["#dff0d8"],
              footer: "light-3",
            }}
            sortable
            onSort={({ direction, property }) =>
              setSortSettings({
                property,
                direction,
              })
            }
            primaryKey={"id"}
            {...(["id", "none"].indexOf(groupBy) === -1 ? { groupBy } : {})}
            data={reportsData}
            columns={getTableColumns(reportsData, groupByProp, sumClicks)}
            sort={sortSettings}
            wideColumns={[2]}
          />
        </Box>
      </Loader>
    </Box>
  );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  const browser = state.browser;
  const { reports: dashboardx, user } = state;
  const userPublishers = user.publishers || [];
  return { dashboardx, browser, userPublishers };
}

// Mapping Action functions into React Component Props.
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, DashboardXActions, AppBarActions),
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardXReports);
