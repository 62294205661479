import React, { Component } from "react";
import { connect } from "react-redux";

import {
  Box,
  Text,
  // Button,
  Collapsible,
} from "grommet";

function RightSidebar(props) {
  const { visible, content } = props;

  if (!visible) return null;

  return null
  // console.info({visible, right: true})
  return (
    <Collapsible direction="horizontal" open={visible}>
      <Box
        flex
        width={content ? "40vw" : "medium"}
        background="light-2"
        elevation="small"
        align="center"
        justify="center"
      >
        {content ? (
          content
        ) : (
          <Text style={{ fontSize: "13px" }}>
            <span style={{ fontWeight: "bold" }}>TrackX 1.2.0</span>
            <br />
            <br />
            * App Events GEOS Select FIX
            <br />
            * Fix Reports GEO value Bug
            <br />* Add delete historical data
          </Text>
        )}
      </Box>
    </Collapsible>
  );

  /*   (
               <Layer>
                 <Box
                   background="light-2"
                   tag="header"
                   justify="end"
                   align="center"
                   direction="row"
                 >
                   <Button
                     icon={<FormClose />}
                     onClick={this.showSidebarClose}
                   />
                 </Box>
                 <Box
                   fill
                   background="light-2"
                   align="center"
                   justify="center"
                 >
                   sidebar
               </Box>
               </Layer>
             )} */
}

function mapStateToProps({ rightSidebar, user }) {
  const { isLoggedIn } = user;
  const visible = rightSidebar.visible && isLoggedIn;
  // pass a content when you want to change the content for the right sidebar
  // to inject the content - get the sidebarsAction Obj and use   injectContent func
  const content = rightSidebar.content;
  return { visible, content };
}

export default connect(mapStateToProps, null)(RightSidebar);
