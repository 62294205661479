const notificationInitial = { display: false, dismissMs: 20000 };

const getNotification = ({ message, title, status, dismissMs }) =>
    Object.assign({}, { display: true, dismissMs: dismissMs || 5000, title, status: status || 'ok', message })
export default function (state = notificationInitial, action) {
    switch (action.type) {
        case 'AUTHENTICATE': {
            console.info(action)
            const message = `Hello ${action.payload.firstName} :)`
            const title = 'Login Successfull';
            return getNotification({ message, title })
        }

        case 'DEAUTHENTICATE': {
            const message = ``
            const title = 'Logout Successfull';
            return getNotification({ message, title })
        }
        case 'AF_APP_BUNDLE_NAME_UPDATED':
        case 'AF_ADN_APP_BUNDLE_NAME_UPDATED':
        case 'AF_APP_BUNDLE_CREATED':
        case 'AF_ADN_APP_BUNDLE_CREATED':
        case 'AF_APP_BUNDLE_EVENT_UPDATED':
        case 'AF_ADN_APP_BUNDLE_EVENT_UPDATED':
        case 'AF_APP_BUNDLE_EVENT_CREATED':
        case 'AF_ADN_APP_BUNDLE_EVENT_CREATED':
        case 'TRACKRC_CONN_LOST': {
            const { notification } = action
            return getNotification(notification)
        }
        case 'AF_APP_BUNDLE_DATA_RESET': {
            const { notification } = action.payload
            return getNotification(notification)
        }
        case 'AF_AGENCY_FETCH_ALL_DATA': {
            const title = 'AppsFlyer Data Loaded';
            return getNotification({ title, dismissMs: 2000 })
        }
        case 'DISMISS_NOTIFICATION':
            return Object.assign({}, notificationInitial);
        case 'NOTIFICATION':
            return Object.assign({}, notificationInitial, { display: true }, action.payload);
        default:
            return state;
    }
}