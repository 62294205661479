import React from "react";
import { connect } from "react-redux";
import Moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import FilterField from "./FilterField";
import FiltersDialog from "../Dialog/FiltersDialog/FiltersDialog";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Box, Button, Grommet, Heading, Layer, Select, Text } from "grommet";
import styled from "styled-components";

import { LinkNext, FormDown, FormUp } from "grommet-icons";
function MobileButton(props) {
  const MButton = styled(Button)`
    border-radius: 0;
  `;
  return (
    <Box
      style={{
        // backgroundColor: "red",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "2%",
        // borderBottom: "1px solid rgba(0,0,0,0.05)",
        // backgroundColor: "rgba(0,0,0)",
      }}
      onClick={props.onClick}
    >
      <MButton style={{ height: 40 }} primary>
        <Box direction="row" pad="6px" gap="6px">
          <Box style={{ fontSize: 14, fontWeight: 700 }}>{props.label}</Box>
          <Box style={{ fontSize: 10 }}>{props.desc}</Box>
        </Box>
      </MButton>
      {props.show ? <FormUp /> : <FormDown />}
    </Box>
  );
}

const THEME = {
  global: {
    input: {
      font: {
        weight: 100,
      },
    },
    control: {
      border: {
        color: "lightgray",
        radius: "5px",
        width: "1px",
      },
    },
  },

  // Date input
  dateInput: {
    icon: {
      size: "15px",
    },
  },
  text: {
    medium: {
      size: "10px",
      weight: 100,
    },
  },
  button: {
    extend: `height:35px;color:lightgray;padding:0`,
    default: {
      background: {
        // color: "white",
      },
      border: {
        color: "lightgray",
        radius: "5px",
        width: "1px",
      },
    },
    color: "red",
  },
  // Selects
  select: {
    icons: {
      color: "lightgray",
    },
    // background: "white",
    // color: "red",
    control: {
      extend: `font-size:10px;height:35px;padding:0`,
    },
    container: {
      // extend: `height:40px;`,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    // padding: "0.5% 0.1%",
  },
  filters: {
    width: "100%",
    // backgroundColor: "green",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    margin: "0.5%",
  },
  fieldsRow: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    // backgroundColor: "red",
  },
  fieldContainer: {
    // width: `10%`,
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    margin: "0.2%",
  },
  filterButton: {
    height: "100%",
    marginRight: "0.5%",
    "&:hover": {
      background: "#f00",
      color: "white",
    },
  },
  filterButtonDesktop: {
    border: "1px solid lightgray",
    display: "flex",
    alignItems: "center",
    color: "white",
    backgroundColor: "#3c8dbc",
    fontWeight: "500",
    maxHeight: "40px",
    padding: "2px 10px",
    borderRadius: "5px",
    fontSize: "10px",
    height: "100%",
  },

  // mobile
  filtersMobileRoot: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    // backgroundColor: "red",
    // height: "300px",
  },
  filtersButtonsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    borderTop: "1px solid lightgray",
    backgroundColor: "#3c8dbc",
  },
  fieldsRowMobile: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "5px",
    width: "100%",
    flexWrap: "wrap",
  },
  fieldsColumnMobile: {
    // backgroundColor: "red",
    display: "flex",
    // flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
    height: "100%",
    margin: "1%",
    width: "100%",
    // backgroundColor: "green",
  },
  fieldContainerMobile: {
    // backgroundColor: "blue",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    width: "50%",
    // margin: "5% 0",
    margin: "5px",
  },
  filtersButton: {
    width: "50%",
    border: "none",
    padding: "2%",
    borderRadius: 0,
    color: "white",
    fontWeight: "500",
    fontSize: "100%",
    backgroundColor: "#3c8dbc",
    "& button:hover": {
      color: "#f00",
    },
  },
}));

//
// FILTERS COMPONENT FORE DESKTOP & TABLET
const FiltersDesktop = (props) => {
  console.info({ props }, "FITLERS");
  const classes = useStyles();
  const { fields, criteria, handleFiltersChange, handleSubmit } = props;

  return (
    <Grommet theme={THEME}>
      <div className={classes.root}>
        <div className={classes.filters}>
          {fields &&
            fields.map((fieldRow, indx) => (
              // row of fields
              <div key={`row_${indx}`} className={classes.fieldsRow}>
                {fieldRow.map((field, i) => {
                  return (
                    // field
                    <div
                      key={`${field.name}_${i}`}
                      className={classes.fieldContainer}
                    >
                      <FilterField
                        field={field}
                        criteria={criteria}
                        handleFiltersChange={handleFiltersChange}
                      />
                    </div>
                  );
                })}
                <div
                  style={{
                    height: "100%",
                  }}
                >
                  {
                    // Add filter button at the end of the filters
                    indx === fieldRow.length && (
                      <button
                        onClick={() => handleSubmit(criteria)}
                        className={classes.filterButtonDesktop}
                      >
                        Filter
                        <LinkNext
                          size="10px"
                          color="white"
                          style={{ marginLeft: "5px" }}
                        />
                      </button>
                    )
                  }
                </div>
              </div>
            ))}
        </div>
      </div>
    </Grommet>
  );
};

const FiltersMobile = (props) => {
  const classes = useStyles();
  const { fields, criteria, handleFiltersChange, handleSubmit, setShow, show } =
    props;
  const mobileButtonDesc = `from: ${Moment(criteria.fromDate).format(
    "YYYY-MM-DD"
  )} | to: ${Moment(criteria.toDate).format("YYYY-MM-DD")}`;

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        padding: 0,
        // backgroundColor: "red",
      }}
    >
      <Grommet theme={THEME}>
        <MobileButton
          label="FILTERS"
          desc={mobileButtonDesc}
          onClick={() => setShow(!show)}
          show={show}
        />

        {
          // on filters open
          show && (
            // <div
            //   onEsc={() => setShow(false)}
            //   onClickOutside={() => setShow(false)}
            //   className={classes.filtersMobileRoot}
            // >
            //   <div className={classes.fieldsRowMobile}>
            //     {fields &&
            //       _.chunk(fields, (fields.length - 1) / 2).map((fieldRow) => (
            //         <div className={classes.fieldsColumnMobile}>
            //           {fieldRow.map((field) => (
            //             // field
            //             <div className={classes.fieldContainerMobile}>
            //               <FilterField
            //                 field={field}
            //                 criteria={criteria}
            //                 handleFiltersChange={handleFiltersChange}
            //               />
            //             </div>
            //           ))}
            //         </div>
            //       ))}
            //   </div>
            //   {/* <div className={classes.filtersButtonsContainer}>
            //     <button
            //       className={classes.filtersButton}
            //       style={{
            //         borderRight: "1px solid lightgray",
            //       }}
            //       label="Close"
            //       onClick={() => setShow(false)}
            //     >
            //       Close
            //     </button>
            //     <button
            //       className={classes.filtersButton}
            //       label="Filter"
            //       onClick={() => {
            //         handleSubmit(criteria);
            //         setShow(false);
            //       }}
            //     >
            //       Filter
            //     </button>
            //   </div> */}
            // </div>
            <div
              onEsc={() => setShow(false)}
              onClickOutside={() => setShow(false)}
              // className={classes.filtersMobileRoot}
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                padding: "5px 0",
                // backgroundColor: "red",
              }}
            >
              <div
                // className={classes.fieldsRowMobile}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",

                  // margin: "5px",
                }}
              >
                {fields &&
                  _.chunk(fields, (fields.length - 1) / 2).map((fieldRow) => (
                    <div
                      // className={classes.fieldsColumnMobile}
                      style={{
                        margin: "5px",
                        // backgroundColor: "green",
                        width: "45%",
                      }}
                    >
                      {fieldRow.map((field) => (
                        // field
                        <div
                        // className={classes.fieldContainerMobile}
                        >
                          <FilterField
                            field={field}
                            criteria={criteria}
                            handleFiltersChange={handleFiltersChange}
                          />
                        </div>
                      ))}
                    </div>
                  ))}
              </div>
            </div>
          )
        }
      </Grommet>
    </div>
  );
};

function Filters(props) {
  // PROPS
  const {
    fields,
    firstLoad,
    handleSubmit,
    onFirstLoad,
    browser,
    queryCriteria,
  } = props;

  // COMPONENT STATE
  const [show, setShow] = React.useState(false);
  const [fieldsForRender, setFieldsForRender] = React.useState();
  const [buttonsForRender, setButtonsForRender] = React.useState();
  const [criteria, setCriteria] = React.useState(queryCriteria);
  // MOBILE OR DESKTOP ?
  const isMobile = !browser.greaterThan.medium;

  // USE EFFECT HOOKE
  React.useEffect(() => {
    //
    //
    // Create criteria
    // the criteria obj use is for saving the fields values local
    //
    let newCriteria = {};
    // For every field in the fields props
    // add it's name and it's value into the criteria
    fields
      .filter((f) => !f.external)
      .forEach((field) => {
        if (field.value) {
          newCriteria[field.name] = field.value;
        }
      });
    //
    if (Object.keys(newCriteria).length) {
      newCriteria = {
        ...criteria,
        ...newCriteria,
      };
      setCriteria(newCriteria);
    } else if (firstLoad) {
      handleSubmit({
        ...(queryCriteria || {}),
        ...criteria,
      });
      onFirstLoad();
    }

    // setup fields for rendring
    // split the fields arr prop into chunks for detrmine the filter's row
    // display max 10 fields inside every single row
    const fieldsWithoutButtons = fields.filter(
      (field) => field.type !== "button"
    );
    setFieldsForRender(_.chunk(fieldsWithoutButtons, 9));

    // Distinguish between a field and a button for view manipulation
    setButtonsForRender(fields.filter((field) => field.type === "button"));
  }, [
    fields,
    criteria,
    setCriteria,
    queryCriteria,
    handleSubmit,
    firstLoad,
    onFirstLoad,
    isMobile,
  ]);

  // HANDLE FILTERS CHANGE
  // Save field's values local until the submmit button is triggerd
  function handleFiltersChange(e, valueObj) {
    let newValues = {};
    let newValue = null;

    if (valueObj) {
      // DIRECT STATE UPDATE (Value Obj)
      newValues = {
        ...criteria,
        ...valueObj,
      };
    } else {
      //  HANDLE EVENT
      const { value, name, type, checked } = e.target;

      newValue = (e.option && e.option) || e.value ? e.value : value;

      if (type === "checkbox") {
        newValue = checked;
      }

      if (typeof newValue === "object" && newValue.label) {
        newValue = newValue.value;
      }

      if (name === "rank") {
        newValue = e.value || [];
      }

      if (["placements", "internalStatus"].indexOf(name) > -1) {
        newValue = e.value.value || 0;
      }

      if (
        ["advertiser", "countries"].indexOf(name) > -1 &&
        typeof newValue !== "object"
      ) {
        console.log("ss");
        newValue = [];
      }

      if (name === "offerSearch") {
        return setCriteria(newValues);
      }
      newValues = {
        ...criteria,
        ...{ [name]: newValue },
      };
    }

    setCriteria(newValues);

    console.info("handleSubmit", { newValues });
    handleSubmit(newValues);
  }

  return isMobile ? (
    <FiltersMobile
      criteria={criteria}
      fields={fields}
      actions={buttonsForRender}
      handleFiltersChange={handleFiltersChange}
      handleSubmit={handleSubmit}
      show={show}
      setShow={setShow}
    />
  ) : (
    // in other case return filters component
    <FiltersDesktop
      criteria={criteria}
      fields={fieldsForRender}
      actions={buttonsForRender}
      handleFiltersChange={handleFiltersChange}
      handleSubmit={handleSubmit}
    />
  );
}

// Getting parameters from Redux state into React Component Props.
function mapStateToProps(state) {
  const browser = state.browser;
  return { browser };
}

export default connect(mapStateToProps)(Filters);
