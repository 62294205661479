import React, { Component } from "react";
import { connect } from "react-redux";

import { Route, Switch, Redirect } from "react-router-dom";

// Page Imports
import HomePage from "./pages/HomePage";
import Login from "../containers/Login";
import NotFoundPage from "./pages/NotFoundPage";
import DashboardXReports from "../containers/DashboardX/DashboardXReports";
import DashboardXHome from "../containers/DashboardX/DashboardXHome";

const PrivateRouteComponent = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={(routeProps) => {
      console.info({ routeProps, rest, user });
      return user.isLoggedIn ? (
        <Component {...routeProps} />
      ) : (
        <Redirect to="/login" />
      );
    }}
  />
);

const PrivateRoute = connect(mapStateToProps, null)(PrivateRouteComponent);

function mapStateToProps({ user }) {
  return { user };
}

export default function Routes(props) {
  return (
    <Switch>
      <Route path={"/login"} exact component={Login} />
      {/* <PrivateRoute path={"/"} exact component={HomePage} /> */}
      <PrivateRoute path={"/"} exact component={DashboardXHome} />
      <PrivateRoute path={"/reports"} exact component={DashboardXReports} />
    </Switch>
  );
}
