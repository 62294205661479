import { DataTable as GrommetDataTable } from "grommet";

import styled from "styled-components";

const DataTable = styled(GrommetDataTable)`
  // padding-bottom: 52px;

  & > thead > tr > th {
    // background-color: white;
    text-align: right;
    // max-width: 55px;
    white-space: pre;
    border-bottom: none;
    ${(props) =>
      !props.wideColumns
        ? ""
        : props.wideColumns
            .map(
              (col) => `
      &:nth-child(${col}) {
        max-width: 100px;
      }
    `
            )
            .join("\n")}
    &,
    & > div {
      & > div > button > div > span,
      & > button > div > span {
        height: 20px;
        font-size: 12px;
        letter-spacing: 0.5px;
        font-weight: 500;

        text-transform: uppercase;
      }
    }
  }
  & > tbody > tr {
    max-height: 30px;

    & > th,
    & > td {
      // max-width: 85px;
      ${(props) =>
        !props.wideColumns
          ? ""
          : props.wideColumns
              .map(
                (col) => `
      &:nth-child(${col}) {
        max-width: 100px;
      }
    `
              )
              .join("\n")}
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }
  }
  & > tbody > tr > td {
    & > div > div {
      min-width: 0px;
      display: flex;
      align-items: start;
    }
    & > div > div > div {
      // background-color: black;
      // white-space: pre-wrap;
    }
    font-size: 5px;
    // background-color: white;
    // max-width: 85px;
    // overflow: hidden;

    font-size: 30px;
    text-align: start;
  }
  & > tbody > tr > td > div {
    // height: 80px;
    white-space: pre;

    // font-size: 5px;
    font-size: 30px;
    // padding-right: 30px;
    // background-color: white;
    min-width: 50px;
    max-width: 200px;
    overflow: hidden;
  }
  & > tbody > tr > td > div > div {
    font-size: 10px;
  }
  & > tbody > tr > td:last-child > div {
    // max-width: 20px;
  }

  // table footer
  & > tfoot > tr > td {
    background-color: white;
    height: 40px;
  }
`;

export const DataTableV2 = styled(GrommetDataTable)`
  border-collapse: collapse;
  & > tbody {
    max-height: ${(props) => (props.size === "large" ? "668px" : "514px")};
  }
  & > thead > tr > th {
    // background-color: #f5f5f5;
    // color: rgb(0, 0, 0, 0.9);
    &,
    & > div {
      min-width: 1.3vw;
      & > button > div > span {
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        text-transform: capitalize;
      }
    }
  }
  & > thead > tr > th {
    // border: 0.01rem solid #525252;
    // border-bottom: 0.2rem solid #525252;
    white-space: pre;
    border-top: none;
  }
  & > thead > tr > th > div > button {
    padding: 0;
  }
  & thead > tr > th > div > button > div > span {
    margin: auto;
  }

  & > tbody {
  }
  & > tbody > tr > td {
    // border: 1px solid rgb(82, 82, 82, 0.2);
    padding: 0 3px;
  }
  & > tbody > tr > td > div {
    padding: 0;

    margin: 0px;
  }
  & > tbody > tr > td > div > div {
    // padding: 0 2px;
    margin: auto;
    // color: gray;
    font-size: 10px;
    // flex-direction: row;
  }
  & > tbody > tr > td > div > div > div {
    line-height: 10px;
    margin: auto 0;
  }
  & > tbody > tr > td > span > span {
    font-size: 14px;
    color: red;
  }

  // Primary table body cell
  // & > tbody > tr > th {
  //   text-align: center;
  //   border: 1px solid rgb(167, 167, 167, 0.3);
  //   border-right: 1px solid rgb(107, 107, 107, 0.7);
  //   padding: 0;
  //   width: fit-content;
  //   // background-color: #f5f5f5;
  // }
  & > tbody {
    overflow-y: overlay;
    overflow-x: scroll;
  }

  // table footer
  & > tfoot > tr > td {
    text-align: center;
    border: 0.01rem solid rgb(167, 167, 167, 0.5);
    // padding: 5px;
    // width: fit-content;
    position: sticky;
    bottom: 0;
    color: black;
    background-color: #bdbdbd;
    // background-color: red;
  }
  & > tfoot > tr > td {
    padding: 0;
  }
  & > tfoot > tr > td > div > div {
    font-weight: 500;
    margin: auto;
    font-size: 9px;
  }
`;

export default DataTable;
