import _ from "lodash";
import Moment from 'moment';

const reportModel = {
  id: null,
  date: null,
  app_bundle: null,
  publisher: null,
  campaign: null,
  country: null,
  impressions: null,
  clicks: null,
  installs: null,
  registrations: null,
  ftd: null,
  revenue: null,
  cost: null,
  profit: null,
  app_name: null,
  // f360_bi: null,
  bp: null,
  bi: null,
  ti: null,
  bt: null,
  bp_p: null,
  bi_p: null,
  bt_p: null,
};

const initialState = {
  loading: 0, 
  reports: [], 
  dashboard: {},
  queryCriteria: {
    
      fromDate: Moment.utc().startOf("day").format(),
      toDate: Moment.utc().format(),
      datePreset: "today"
  
  },
  options: {
    app_bundle: [],
    publisher: [],
    campaign: [],
    country: []
  }
}


function getState(state) {

  const options = {};

  Object.keys(state.options)
    .forEach(optName => {
      options[optName] = _.uniqBy(state.reports, optName)
        .map((o) => {
          const value = o[optName];
          const type = optName;
          const label = optName === 'app_bundle'
            ? `${o.app_bundle} :: ${o.app_name.replace(/_/g, '-').replace(/ /g, '')}`
            : o[optName]
          return {
            value,
            type,
            label
          }
        })
    })


    return {
      ...state,
      options
    }

}

const appsflyer = (state = initialState, action) => {
  switch (action.type) {
    case "AF_AGENCY_FETCH_ALL_DATA":
    case "AF_AGENCY_LOADING_DATA": {
      const { reports: raw, ...rest } = action.payload;
      const reports = (raw || []).map((o) => _.pick(o, _.keys(reportModel)));
      return { ...state, ...rest, reports };
    }

    case 'USER_DATA_RESPONSE' : {
      return getState({
        ...state,
        ...action.payload
      })
    }
    case 'DEAUTHENTICATE':
       return initialState
    case "TRACKRC_CONN_LOST": {
      return Object.assign({}, state, { loading: false });
    }
 
    default:
      return state;
  }
};

export default appsflyer;
