import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
// Grommet Imports
import { Heading, Paragraph, Button, Box } from "grommet";
import SummaryByDateChart from '../Charts/SummaryByDate/SummaryByDateChart';
import ActivePieChart from '../Charts/ActivePieChart/ActivePieChart';
import Numeral from 'numeral';

// Action Imports
import DashboardActions, { getDashboardData } from "../../actions/DashboardActions";

function HomePage(props) {
  const {
  } = props;

  // useEffect(() => {
  //   // console.info({summary})
  //   if (!summary.length)
  //     // getDashboardData()
  // }, [summary, getDashboardData])

  // console.info({profitPub})
  return (
    <Box
      flex
      fill
      width="100%"
      margin="none"
      overflowY="auto"
      alignSelf="center"
    >
      Dashboard

    </Box>
  );

}

const mapStateToProps = (state, props) => {
  const { user, dashboard } = state;
  const username = user.username;


  return { username };
};

const mapDispatchToProps = {
  ...DashboardActions
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
