import Ax from "axios";
import Moment from "moment";
import { debounce } from 'lodash';

// action type exports
export const SET_TEST = "SET_TEST";

// actions
export function setTest(test) {
  return (dispatch) => {
    dispatch({
      type: SET_TEST,
      payload: test,
    });
  };
}

export function getDashboardDataOriginal({ startDate, endDate } = {}) {
  // console.log(startDate);
  // console.log(Moment.utc().subtract(1, "month"));
  return async (dispatch) => {
    const url = `/api/dashboard/summary`;
    const payload = {
      startDate: startDate || Moment.utc().subtract(1, "month"),
      endDate: endDate || Moment.utc(),
    };
    const { data } = await Ax.post(url, payload);
    // console.info({response})
    dispatch(data);
  };
}

const innerFunction = debounce( async (dispatch, query) => {
  const { startDate, endDate, ...rest } = query
  const url = `/api/dashboard/summary`;
  const payload = {
    startDate: startDate || Moment.utc().subtract(1, "month"),
    endDate: endDate || Moment.utc(),
    ...rest
  };
  const { data } = await Ax.post(url, payload);
  // console.info({response})
  dispatch(data);
}, 1000)

const getDashboardData = (query) => dispatch => innerFunction(dispatch, query);

export default {
  getDashboardData,
};
