/*
 * Yunus Emre Dilber
 * https://github.com/yunusemredilber
 * */

import React, { Component } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import MainTheme from "./themes/main-theme";

// Grommet Imports
import { Box, Grommet, ResponsiveContext } from "grommet";

import AppBar from "./containers/AppBar";
import MainSidebar from "./containers/MainSidebar/MainSidebar";
import RightSidebar from "./containers/RightSidebar";

import Routes from "./components/Routes";
import Notification from "./containers/Notification";
import { logout } from "./actions/AuthActions";

class App extends Component {
  constructor(props) {
    super(props);
    // this.sidebarToggle = this.sidebarToggle.bind(this);
    // this.showSidebarToggle = this.showSidebarToggle.bind(this);
    // this.showSidebarClose = this.showSidebarClose.bind(this);
  }

  render() {
    const { user, dispatch } = this.props;
    // console.info(this.props);
    return (
      <Router>
        <Grommet theme={MainTheme} full>
          <ResponsiveContext.Consumer>
            {(size) => (
              <Box fill>
                <AppBar />
                <Notification />
                <Box direction="row" flex overflow={{ horizontal: "hidden" }}>
                  <MainSidebar
                    onLogout={() => dispatch(logout())}
                    // user={user}
                  />
                  <Routes />
                  <RightSidebar />
                </Box>
              </Box>
            )}
          </ResponsiveContext.Consumer>
        </Grommet>
      </Router>
    );
  }
}

function mapStateToProps({ user, browser }) {
  return { user, browser };
}

export default connect(mapStateToProps, null)(App);
