import React from "react";

import Numeral from "numeral";

import MomentTZ from "moment-timezone";
import _ from "lodash";



import Cell from "../../components/Cell";


export function getTableColumns(reportData, { groupBy }, sumClicks) {
  // For FOOTER Percentage FIX

  const checkFooterRow = (props) => sumClicks === props.clicks;

  // FOR GROUPBY percentage FIX

  let groupBySum = null;

  if (groupBy) {
    groupBySum = _.groupBy(reportData, groupBy);
    for (const groupEntity in groupBySum) {
      groupBySum[groupEntity] = groupBySum[groupEntity]
        .map((o) => o.clicks)
        .reduce((a, b) => a + b);
    }
  }

  const isGroupByRow = (props) => {
    const { clicks } = props;
    const groupVal = props[groupBy];
    const isGroupRow = groupBySum[groupVal] === clicks;
    return isGroupRow;
  };

  const defaultRenderer = (propName, type = "", color = "") => (props) => {
    let value = props[propName.toLowerCase()];
    let extraStyle = {};
    const isFooterRow = checkFooterRow(props);

    if (type === "percent") {
      if (isFooterRow || (groupBy && isGroupByRow(props))) {
        const [numeralField] = propName.split("_p");
        const realPercent = props[numeralField] / props.ti;
        value = realPercent;
      }
      if (value === 1)
        extraStyle = Object.assign(extraStyle, { fontWeight: 700 });
      // FOOTER PERCENT FIX
    }

    if (type === "numeral") {
      value = Numeral(value).format("0,0");
    } else if (type === "currency") {
      extraStyle = Object.assign(extraStyle, {
        fontWeight: 700,
        fontSize: "11px",
        letterSpacing: "1px",
      });
      value = Numeral(value).format("0,0.0$");
    }

    if (propName === "campaign" && value && value.length > 25) {
      value = value.slice(0, 22) + "...";
    } else if (type === "decimalp") {
      // console.info({decimal: value})
      value = Numeral(value).format(value > 0 ? "0.[000]" : "0");
    } else if (type === "percent") {
      value = Numeral(value).format(value > 0 ? "0.[0]%" : "0");
    }

    if (isFooterRow) {
      extraStyle = { fontSize: "11px", fontWeight: 600 };
    }

    return (
      <Cell flex color={color} {...extraStyle} type={type} align="start">
        {value}
      </Cell>
    );
  };

  const namer = (propName) => {
    switch (propName) {
      case "impressions":
        return "IMPS";
      case "registrations":
        return "REG";
      case "installs":
        return "INST";
      case "publisher":
        return "PUB";
      case "profit":
        return "NET";
      case "revenue":
        return "REV";
      case "country":
        return "GEO";
      case "ftd":
        return "FTD";
      case "bt_p":
        return "BT%";
      case "bi_p":
        return "BI%";
      case "bp_p":
        return "BP%";
      case "bp":
      case "bt":
      case "bi":
        return propName.toUpperCase();
      case "ti":
        return "TOTAL";
      default:
        return propName.toUpperCase();
    }
  };

  const simpleColumns = [
    //   "publisher",
    "campaign",
    "country",
  ];

  const numeralColumns = [
    "impressions",
    "clicks",
    "ti",
    "installs",
    "bi",
    "bi_p",
    "bp",
    "bp_p",
    "bt",
    "bt_p",
    "registrations",
    "ftd",
  ];

  const currencyColumns = [
    {
      prop: "revenue",
      color: "green",
    },
    // {
    //   prop: "cost",
    //   color: "red",
    // },
    // {
    //   prop: "profit",
    //   color: "blue",
    // },
  ];

  const columnDefs = [
    {
      property: "date",
      header: "DATE",
      render: ({ date }) => (
        <Cell style={{ whiteSpace: "pre" }}>
          {date && MomentTZ(date).format("YYYY-MM-DD")}
        </Cell>
      ),
    },
    {
      property: "app_bundle",
      header: "BUNDLE",
      render: ({ app_bundle, app_name }) => (
        <Cell style={{ whiteSpace: "pre", fontWeight: 500 }}>
          {app_name}
          <Cell
            style={{
              maxWidth: "110px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "inline",
            }}
          >
            {app_bundle}
          </Cell>
        </Cell>
      ),
    },
    {
      property: "publisher",
      header: "PUBLISHER",
      render: ({ publisher, isAdn }) => {
        const isGroup = !publisher;
        const label = isGroup ? "Group" : isAdn ? "AD" : "AG";
        const color = isGroup ? "black" : isAdn ? "red" : "blue";

        return (
          <Cell
            style={{
              color: "white",
              fontWeight: "bold",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Cell
              style={{
                paddingLeft: "2px",
                color: "black",
              }}
            >
              {publisher}
            </Cell>
            <div
              style={{
                backgroundColor: color,
                borderRadius: "50%",
                height: "12px",
                width: "12px",
                fontSize: "7px",
                marginLeft: "2%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {label}
            </div>
          </Cell>
        );
      },
    },
    ...simpleColumns.map((propName) => ({
      property: propName,
      header: namer(propName),
      render: defaultRenderer(propName),
      // footer: "x"
    })),
    ...numeralColumns.map((propName) => {
      const isPercentage = propName.indexOf("_p") > -1;
      return {
        property: propName,
        header: namer(propName),
        render: defaultRenderer(propName, isPercentage ? "percent" : "numeral"),
        aggregate: "sum",
        footer: { aggregate: isPercentage ? true : true },
      };
    }),
    ...currencyColumns.map(({ prop, color }) => ({
      property: prop,
      header: namer(prop),
      render: defaultRenderer(prop, "currency", color),
      aggregate: "sum",
      footer: { aggregate: true },
    })),
  ];
  // const model = {}
  // columnDefs.forEach(o => model[o.property] = 1 / columnDefs.length);
  // console.info(JSON.stringify(model))
  return columnDefs;
}

export function getRowProps(data) {
  let rowProps = {
    123456789: {
      background: "black",
    },
  };

  return rowProps;
}

export function getGroupByOpts(groupBy) {
  return [
    ...[groupBy !== "id" ? "none" : undefined],
    "app_bundle",
    "app_name",
    "campaign",
    "publisher",
    "date",
    "country",
  ].filter((s) => s);
}


const functions = {
  getRowProps,
  getTableColumns,
  getGroupByOpts
};
export default functions