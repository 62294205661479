import React, {
  PureComponent,
  Component,
  useRef,
  useEffect,
  useState,
} from "react";
// import { Bar } from 'react-chartjs-2';
import { Heading, Paragraph, Button, Box } from "grommet";
import DemoData from "./demo-date";
import {
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import Numeral from "numeral";
import Moment from "moment";

const DEFAULT_INDICATORS = {
  clicks: {
    hide: false,
    indicator: "line",
    type: "monotone",
    yAxisId: "left",
    dataKey: "clicks",
    fill: "RGB(84, 71, 140,0.5)",
    stroke: "RGB(84, 71, 140,0.5)",
    strokeWidth: 2,
    dot: false,
  },
  // revenue: {
  //   hide: false,
  //   indicator: "line",
  //   type: "monotone",
  //   yAxisId: "right",
  //   dataKey: "revenue",
  //   stroke: "RGB(99, 154, 150,0.5)",
  //   strokeWidth: 2,
  //   dot: false,
  // },
  // cost: {
  //   hide: false,
  //   indicator: "line",
  //   type: "monotone",
  //   yAxisId: "right",
  //   dataKey: "cost",
  //   stroke: "RGB(239, 48, 84, 0.5)",
  //   strokeWidth: 2,
  //   dot: false,
  // },
  profit: {
    hide: false,
    indicator: "line",
    type: "monotone",
    yAxisId: "right",
    dataKey: "profit",
    stroke: "RGB(44, 104, 154, 0.5)",
    strokeWidth: 2,
    dot: false,
  },
  ftd: {
    hide: false,
    barSize: 5,
    indicator: "bar",
    dataKey: "ftd",
    yAxisId: "left",
    fill: "rgba(84, 71, 140, 0.4)",
  },
  installs: {
    hide: false,
    barSize: 5,
    indicator: "bar",
    dataKey: "installs",
    yAxisId: "left",
    fill: "RGB(168, 39, 219, 0.2)",
  },
  registrations: {
    hide: false,
    barSize: 5,
    indicator: "bar",
    dataKey: "registrations",
    yAxisId: "left",
    fill: "RGB(84, 242, 210, 0.4)",
  },
};

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, stroke, value } = this.props;

    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
        {value}
      </text>
    );
  }
}

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          // transform="rotate(-35)"
          style={{
            fontSize: "13px",
            fontWeight: "600",
          }}
        >
          {Moment(payload.value).format("MM-DD")}
        </text>
      </g>
    );
  }
}

const renderLegend = (props) => {
  const { payload, indicators, isMobile } = props;

  // console.log(indicators["clicks"]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        // borderTop: "1px dashed lightgray",
        padding: "5px",
        // paddingTop: "20px",
        // overflowX: "auto",
      }}
    >
      {payload.map((entry, index) => (
        <div
          key={`item-${index}`}
          style={{
            display: "flex",
            // width: isMobile ? "10%" : "5%",
            margin: "0 10px",
            alignItems: "center",
            flexDirection: isMobile ? "column" : "row",
            // flexWrap: "wrap",
          }}
          onClick={() => props.onClick(payload[index])}
        >
          <div
            style={{
              margin: "0 5px",
              backgroundColor: entry.color,
              opacity: indicators[entry.dataKey].hide ? "0.3" : "1",
              // padding: "5px",
              width: isMobile ? "10%" : "5%",
              borderRadius: "50%",

              textTransform: "capitalize",
              fontSize: isMobile ? "10px" : "11px",
              border: `1px solid ${entry.hide ? "white" : "gray"}`,
              // boxShadow: "0px 2px 5px 0px rgba(0,0,0,0.55)",
              height: "9px",
              width: "9px",
              marginBottom: isMobile ? "5px" : "none",
              // marginTop: isMobile ? "20px" : 0,
            }}
          ></div>
          <div
            style={{
              textTransform: "capitalize",
              color: entry.hide ? "blue" : "gray",
              // fontWeight: isMobile ? "500" : "500",
            }}
          >
            {entry.value}
          </div>
          {/* {indicators[entry.dataKey] && console.log(indicators[entry.dataKey])} */}
        </div>
      ))}
    </div>
  );
};

function SummaryByDate(props) {
  const [data, setData] = useState(props.data);
  const [indicators, setIndicators] = useState(DEFAULT_INDICATORS);
  const isMobile = props.maxWidth < 1600;

  const {
    containerHeight, // the height of the element that contain the chart, use later for set the chart height
  } = props;
  // console.log(containerHeight);
  useEffect(() => {
    // if (
    //   (!data.length && props.data.length) ||
    //   data.length !== props.data.length
    // )
    setData(props.data);
  }, [data, setData, props.data]);

  const labelFormatter = (v, dollars = false) => {
    // console.info(v, Numeral(v).format('0 a'))
    return Numeral(v).format(dollars ? "$0a" : "0a");
  };
  return (
    <ResponsiveContainer
      width={"100%"}
      height={props.maxWidth > 1600 ? containerHeight : 350}
    >
      <ComposedChart
        // width={500}
        // height={400}

        data={data}
        margin={{
          top: 40,
          right: props.maxWidth < 1600 ? -5 : 20,
          bottom: 10,
          left: props.maxWidth < 1600 ? 10 : 20,
        }}
        // mirror={true}
      >
        <CartesianGrid stroke="#f5f5f5" />
        {/* <CartesianGrid strokeDasharray="2 2" /> */}

        <XAxis
          // height={50}
          dataKey="date"
          height={60}
          tick={<CustomizedAxisTick />}
          // scale="band"
          //  label={{
          //      value:
          //      'date',
          //      position: 'insideBottomRight',
          //      offset: 0 }}
          tickLine={false}
          // scale="auto"
          tickFormatter={(t) => {
            if (typeof t === "string" && t.indexOf("-") > -1) {
              // console.info("SummaryByDateChart", t)
              const [y, m, d] = t.split("-");
              return `${m}-${d}`;
            }
            return t;
          }}
          //  tick={<DateTick />}
        />
        <YAxis
          type="number"
          name="clicks"
          width={40}
          yAxisId="left"
          tickFormatter={(x) => labelFormatter(x, false)}
          tickLine={false}
        />
        {/* <YAxis
          type="number"
          name="installs"
          width={40}
          yAxisId="left1"
          axisLine={false}

          tickFormatter={(x) => labelFormatter(x, false)}
          tickLine={false}

          // label={{ value: 'Clicks', angle: -90, position: 'insideLeft',  }}
        />
       <YAxis
          type="number"
          name="ftd"
          width={40}

          axisLine={false}
          yAxisId="left2"
          tickFormatter={(x) => labelFormatter(x, false)}
          tickLine={false}

          // label={{ value: 'Clicks', angle: -90, position: 'insideLeft',  }}
        /> */}
        <YAxis
          type="number"
          name="dollars"
          yAxisId="right"
          orientation="right"
          width={60}
          tickLine={false}
          tickFormatter={(v) => labelFormatter(v, true)}
          dataKey="profit"
          // label={{ value: 'Dollars', angle: -90, position: 'outsideLeft' }}
        />
        <Tooltip
          formatter={(value, name, props) => {
            // console.info({value, name, props})
            const fval =
              ["clicks", "installs", "ftd", "registrations"].indexOf(name) > -1
                ? Numeral(value).format("0,0")
                : Numeral(value).format("$0,0.00");
            return [fval, name];
          }}
        />
        <Legend
          isMobile={isMobile}
          verticalAlign="bottom"
          margin={"100px"}
          indicators={indicators}
          content={renderLegend}
          onClick={({ dataKey, inactive }) =>
            setIndicators((indicators) => {
              const indicator = {
                [dataKey]: {
                  ...indicators[dataKey],
                  hide: !indicators[dataKey].hide,
                },
              };
              // console.info({ indicators, indicator })
              return {
                ...indicators,
                ...indicator,
              };
            })
          }
        />

        {
          // Create Indicators
          Object.values(indicators).map((indObj) => {
            const { indicator, ...config } = indObj;
            if (indicator === "line") {
              return <Line {...config} key={config.dataKey} />;
            }
            return <Bar {...config} key={config.dataKey} />;
          })
        }
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default SummaryByDate;
