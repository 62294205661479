import { Box } from "grommet";

import styled from "styled-components";

const Cell = styled(Box)`
  font-size:${(props) => (props.fontSize ? props.fontSize : "12px")}; 
  & > .desc {
    font-size: 5px;
  }
  letter-spacing: ${(props) => (props.letterSpacing ? props.letterSpacing : "normal")}; 
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "normal")}; 
  color: ${(props) => (props.type ? props.color : "black")};
  text-overflow: ${(props) => (props.type ? "inherit" : "ellipsis")};
  margin: 0px;
  padding: 0;
  text-align: ${(props) => (props.start ? "start" : "center")};
`;

export default Cell;
